import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { currencyService } from 'src/api/services/currency';
import { currencyExchangeRateService } from 'src/api/services/currency-exchanges-service';
import { ISelectBox } from 'src/api/types/base-types';
import { TCreateCurrencyExchangePayload } from 'src/api/types/currency-exchanges';
import InitLoading from 'src/components/App/Loading/InitLoading';
import Button from 'src/components/Kit/Button';
import FormGenerator from 'src/components/Kit/FormGenerator/index';
import { dropDownDate } from 'src/constants/dropdown-date';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { modalActions } from 'src/context/modals';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import styles from 'src/pages/Company/Show/CompanyFinancialInformation/AddUpdate/components/SelectFinancialData/components/FinancialItemRow/FinancialItemRow.module.scss';
import { validationSchema } from 'src/pages/Settings/CurrencyExchange/AddUpdate/validation-schema';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { MODAL_TYPES } from 'src/types/modals';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { createCurrencyExchangeSchema, createSchemaType } from './schema';

interface IParams {
    [id: string]: string;
}

const AddUpdate: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const navigate = useNavigate();
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const hasCreatePermission = checkPermissionAccess(PERMISSION_TYPES.CURRENCY_EXCHANGE_EDIT);
    const hasUpdatePermission = checkPermissionAccess(PERMISSION_TYPES.CURRENCY_EXCHANGE_EDIT);
    const [loading, setLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [formSchema, setFormSchema] = useState<createSchemaType>(createCurrencyExchangeSchema);

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<TCreateCurrencyExchangePayload>({
        defaultValues: generateDefaultValueFromFormSchema(formSchema),
        mode: 'all',
        resolver: yupResolver(validationSchema()),
        shouldFocusError: false,
    });

    const updateCurrency = async (payload: TCreateCurrencyExchangePayload) => {
        if (loading) return;
        try {
            const exchangeRateId = id ? Number(id) : undefined;
            setLoading(true);
            const request = exchangeRateId ? currencyExchangeRateService.update(exchangeRateId, payload) : currencyExchangeRateService.create(payload);
            const response = await request;
            toast.fire({
                icon: TOAST_STATUS.SUCCESS,
                title: response?.data?.message || 'Operation Done Successfully',
            });
            setLoading(false);
            navigate(ROUTE_CONSTANTS.SETTINGS.CURRENCY_EXCHANGE.ROOT.ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setLoading(false);
        }
    };

    const onSubmit = useCallback(async (_val: TCreateCurrencyExchangePayload) => {
        const { currency, month, year, new_currency } = _val;
        const tempData = {
            currency_id: new_currency,
            exchange_rate_to_usd: currency,
            month: +month,
            year: year ? +dayjs(year).format('YYYY') : undefined,
        };

        modalActions.clearModals();
        modalActions.addModal(MODAL_TYPES.CUSTOM_MODAL, {
            header: 'Alert',
            children: (
                <div className={styles.nullItemWarningWrapper}>
                    <div className={styles.bodyText}>
                        Since Your are permitted to update each currency exchange rate
                        <br />
                        <strong> only once within a 24-hour period</strong>
                        <br />
                        Are You Sure about the value that you have entered?
                    </div>
                </div>
            ),
            footer: (
                <div className={styles.customModalActionWrapper}>
                    <Button color="danger" className={styles.customModalAction} onClick={() => modalActions.clearModals()}>
                        <span>Cancel</span>
                    </Button>
                    <Button
                        color="primary"
                        className={styles.customModalAction}
                        onClick={() => {
                            updateCurrency(tempData as any);
                            modalActions.clearModals();
                        }}
                    >
                        <span>Yes, I'm sure</span>
                    </Button>
                </div>
            ),
            text: '',
        });
    }, []);

    const handleGetValues = async () => {
        setInitialLoading(true);
        if (isEditingRule) {
            // @ts-ignore
            await getDetails();
        }

        try {
            let res = await currencyService.selectBox();

            const currencyList: ISelectBox[] = res.data.data.map((c: any) => ({
                label: c.label,
                value: String(c.value),
            }));

            setFormSchema((prev) => ({
                ...prev,
                new_currency: { ...prev.new_currency, data: currencyList, props: { ...prev.new_currency.props, disabled: isEditingRule } },
            }));
            setInitialLoading(false);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        } finally {
            setInitialLoading(false);
        }
    };

    const getDetails = async () => {
        try {
            const currentId = Number(id || 0);
            let res = await currencyExchangeRateService.getOne(currentId);
            const monthInList = res.data.data.month ? dropDownDate.find((item) => `${item.value}` === `${res.data.data.month}`) : undefined;
            reset({
                new_currency: res.data.data.exchange_rate,
                currency: res.data.data.exchange_rate,
                month: monthInList?.value,
                year: new Date(res.data.data.year, 1, 1) as unknown as string,
                dollar: 1,
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
            setInitialLoading(false);
            navigate(ROUTE_CONSTANTS.SETTINGS.CURRENCY_EXCHANGE.ROOT.ABSOLUTE);
            throw new Error('Could not get details');
        }
    };

    useEffect(() => {
        handleGetValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isEditingRule && !hasUpdatePermission) return <></>;
    if (!isEditingRule && !hasCreatePermission) return <></>;

    if (initialLoading) return <InitLoading />;

    return (
        <DashboardContentWrapper>
            <div className="grid justify-content-center my-8">
                <div className="xs:col:12 md:col-8 xl:col-5">
                    <span className="block text-2xl font-semibold">{isEditingRule ? 'Update Currency Rate' : 'Add New Currency Exchange Rate'}</span>
                    <div className="mt-5">
                        <FormGenerator
                            // onSubmit={handleSubmit(onSubmit)}
                            control={control}
                            errors={errors}
                            schema={formSchema}
                            noBackgroundLayout
                        />
                        <Button disabled={loading} loading={loading} color="primary" onClick={handleSubmit(onSubmit)}>
                            {isEditingRule ? 'Update' : 'Create'}
                        </Button>
                        <Button
                            type="button"
                            onClick={() => {
                                navigate(ROUTE_CONSTANTS.SETTINGS.CURRENCY_EXCHANGE.ROOT.ABSOLUTE);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </DashboardContentWrapper>
    );
};

export default AddUpdate;
