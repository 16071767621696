import { FormEventHandler, Fragment, ReactNode } from 'react';
import styles from './FormGenerator.module.scss';
import { FORM_INPUTS } from 'src/types/form';
import { Control, Controller, ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import DashboardContentWrapper from '../../../layouts/DashboardContentWrapper';
import FormSelect from './FormSelect';
import FormText from './FormText';
import FormTags from './FormTags';
import FormHidden from './FormHidden';
import FormDatePicker from './FormDatePicker';
import { IFormGeneratorSchemaType } from 'src/types/form-generator-schema-type';
import LocationPicker from './LocationPicker';
import FormTextarea from './FormTextarea';
import FormNumber from './FormNumber';
import ImageUploader from './ImageUploader';
import FormFile from './FormFile';
import FormRadio from './FormRadio';
import { classNames } from 'primereact/utils';
import FieldSet from './FieldSet';
import FormMultiSelect from './FormMultiSelect';
import FormDateDynamicPicker from './FormDateDynamicPicker';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import FormEditor from 'src/components/Kit/FormGenerator/FormEditor';
import FormChips from './FormChips';
import { generateDefaultValueFromFormSchema } from 'src/utils/generate-default-value-from-form-schema';
import PlusIcon from 'src/assets/Icons/PlusIcon';
import CloseIcon from 'src/assets/Icons/CloseIcon';
import { RedError } from 'src/assets/Icons/RedError';
import FormRadio2 from 'src/components/Kit/FormGenerator/FormRadio2';
import FormDropDown from './FormDropDown';

interface IProps {
    loading?: boolean;
    control: Control<any, any>;
    errors: any;
    schema: Record<any, IFormGeneratorSchemaType>;
    onSubmit?: FormEventHandler<HTMLFormElement>;
    footer?: ReactNode;
    buttons?: ReactNode;
    title?: string;
    titleSuffix?: ReactNode;
    noBackgroundLayout?: boolean;
    setValue?: UseFormSetValue<any>;
}

const FormGenerator = ({
    onSubmit,
    loading,
    control,
    schema,
    errors,
    title = '',
    titleSuffix = null,
    buttons,
    footer,
    noBackgroundLayout,
    setValue = () => {},
}: IProps) => {
    // const [modifiedField, setModifiedField] = useState<any>(null);
    const formFieldComponent = (field: ControllerRenderProps<FieldValues, string>, attr: any, errorMessage: FieldError) => {
        switch (attr.type) {
            case FORM_INPUTS.FIELD_SET:
                return <FieldSet {...attr} />;
            case FORM_INPUTS.SELECT:
                return <FormSelect field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.MULTI_SELECT:
                return <FormMultiSelect field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.RADIO:
                return <FormRadio field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.RADIO_2:
                return <FormRadio2 field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.TEXT:
                return <FormText field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.FILE:
                return <FormFile field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.NUMBER:
                return <FormNumber field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.TEXTAREA:
                return <FormTextarea field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.TAG:
                return <FormTags field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.CHIPS:
                return <FormChips field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.EDITOR:
                return <FormEditor field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.HIDDEN:
                return <FormHidden field={field} {...attr} />;
            case FORM_INPUTS.DATE:
                return <FormDatePicker field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.DATE_DYNAMIC:
                return <FormDateDynamicPicker field={field} errorMessage={errorMessage} setValue={setValue} {...attr} />;
            case FORM_INPUTS.GEOGRAPHY_LOCATION:
                return <LocationPicker field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.IMAGE_UPLOADER:
                return <ImageUploader field={field} errorMessage={errorMessage} {...attr} />;
            case FORM_INPUTS.DROPDOWN:
                return <FormDropDown field={field} errorMessage={errorMessage} {...attr} />;
            default:
                return <FormText field={field} errorMessage={errorMessage} {...attr} />;
        }
    };

    const formWrapper = (
        <>
            {Object.entries(schema).map(([name, attr]: any, schemaIndex) => {
                if (attr.type === FORM_INPUTS.REPEATER) {
                    return (
                        <div className="col-12" key={schemaIndex}>
                            <div className={styles.formRepeater}>
                                <label>{attr.label}</label>
                                {attr.fields?.map((field: any, index: number) => (
                                    <Fragment key={field.id}>
                                        <div className="col-12">
                                            <div className={styles.formRepeaterItem}>
                                                {Object.entries(attr?.childrenSchema).map(([childrenName, childrenAttr]: any) => (
                                                    <Controller
                                                        key={`${index}.${childrenName}`}
                                                        name={`${name}.${index}.${childrenName}`}
                                                        control={control}
                                                        render={({ field }) =>
                                                            formFieldComponent(
                                                                field as ControllerRenderProps<FieldValues, string>,
                                                                childrenAttr,
                                                                errors[name]?.[index]?.[childrenName]
                                                            )
                                                        }
                                                    />
                                                ))}
                                                <CloseIcon onClick={() => attr.props.remove(index)} />
                                            </div>
                                            {errors[name] && errors[name].message ? (
                                                <div className={'form-input-error'}>
                                                    <RedError />
                                                    <span>{errors[name].message}</span>
                                                </div>
                                            ) : null}
                                        </div>
                                    </Fragment>
                                ))}
                                <div
                                    className={styles.formRepeaterAddButton}
                                    onClick={() => attr.props.append(generateDefaultValueFromFormSchema(attr?.childrenSchema))}
                                >
                                    <PlusIcon />
                                </div>
                            </div>
                        </div>
                    );
                }

                return <Controller key={name} name={name} control={control} render={({ field }) => formFieldComponent(field, attr, errors[name])} />;
            })}

            {footer}
        </>
    );

    return (
        <form noValidate onSubmit={onSubmit} className={classNames(loading ? styles.formLoading : '')}>
            <div className="grid">
                {noBackgroundLayout ? (
                    formWrapper
                ) : (
                    <DashboardContentWrapper title={title} titleSuffix={titleSuffix}>
                        {formWrapper}
                        {footer}
                    </DashboardContentWrapper>
                )}
            </div>

            {buttons ? <div className="mt-4">{buttons}</div> : ''}
        </form>
    );
};

export default FormGenerator;
