import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { IDataTablePayload, IDataTableResponse } from 'src/api/types/table';
import { IExchangeRage } from '../types/exchange-rate';

export const currencyExchangeRateService = {
    getAll(params?: IDataTablePayload) {
        return AxiosInstance.get<IDataTableResponse<IExchangeRage>>(API_ROUTES.CURRENCY_EXCHANGE.GET_ALL, { params });
    },
    getOne(exchangeRate: number) {
        return AxiosInstance.get<IAxiosResponse<IExchangeRage>>(API_ROUTES.CURRENCY_EXCHANGE.GET_ONE(exchangeRate));
    },
    create(data: any) {
        return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.CURRENCY_EXCHANGE.CREATE, data);
    },
    update(exchangeRate: number, data: any) {
        return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.CURRENCY_EXCHANGE.MODIFY(exchangeRate), data);
    },
};
