import { DropdownProps, Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { dropDownDate } from 'src/constants/dropdown-date';

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label: string;
    placeholder: string;
    className: string;
    errorMessage: FieldError;
    props: {
        options: { label: string; value: string }[];
    } & DropdownProps;
}
const FormDropDown = ({
    field,
    label,
    placeholder,
    className,
    errorMessage,
    props = {
        options: [],
    },
}: IProps) => (
    <div className={classNames('col-12', className ? className : '')}>
        <div className="form-field">
            <label className={field.name}>
                {label || placeholder} {props.required ? <span className="required">*</span> : ''}
            </label>
            <Dropdown
                optionLabel="label"
                optionValue="value"
                value={field.value}
                options={props.options}
                onChange={(val) => {
                    field.onChange(val);
                }}
                placeholder={placeholder}
                disabled={props.disabled}
                required
            />
        </div>
    </div>
);

export default FormDropDown;
