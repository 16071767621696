import { ITagListResponse } from 'src/api/types/tags';
import { USER_REQUEST_STATUS } from 'src/enums/user/request-status';

const API_URL = window?.REACT_APP_API_URL || process.env.REACT_APP_API_URL_lOCAL || `http://localhost:9081/api`;
export const UPLOAD_URL = window?.REACT_APP_API_URL
    ? `${window?.REACT_APP_API_URL}/media/upload`
    : 'https://api-teaserplatform.dev.teaser.co/api/admin/media/upload';
export const API_ROUTES = {
    BASE_URL: '/',
    MEDIA: {
        VIEW_PDF: (UUID: string) => `/media/${UUID}/signed-view-pdf-url`,
        DELETE_FILE_URL: (UUID: string) => `/media/${UUID}/signed-delete-file-url`,
        DOWNLOAD_FILE_URL: (UUID: string) => `/media/${UUID}/signed-download-file-url`,
        UPDATE_DESCRIPTION: (UUID: string) => `/media/${UUID}/description`,
        USAGE_DETAIL: (UUID: string) => `/media/${UUID}/usage/detail`,
        SEARCH: `/media/search`,
        MEDIAS: `/media`,
    },
    AUTH: {
        LOGIN: '/login',
        GET_PROFILE: '/profile',
        LOGOUT: '/logout',
    },
    USER: {
        GET_ALL: '/users',
        ADD_NEW: '/users/create',
        CHANGE_ACTIVATION: (id: number) => `/user/${id}/activation`,
        GET_ONE: (id: number) => `/users/${id}`,
        UPDATE: (id: number) => `/users/${id}/edit`,
        DELETE: (id: number) => `/users/${id}/delete`,
        REQUESTS: (status: USER_REQUEST_STATUS) => `/users/request-access/${status}`,
        CHANGE_REQUEST_STATUS: '/users/request-access/change-status',
    },
    ROLES: {
        GET_ALL: '/roles',
        GET_PERMISSIONS: '/roles/list/permissions',
        ADD_NEW: '/roles',
        UPDATE: (id: number) => `/roles/${id}`,
        DELETE: (id: number) => `/roles/${id}`,
        GET_ONE: (id: number) => `/roles/${id}`,
        SELECT_BOX: '/roles/list/options',
        GET_SELECT_BOX: (type: number | undefined) => `/roles/list/options?type=${type}`,
    },
    PERSON: {
        GET_ALL: '/persons',
        ADD_NEW: '/persons',
        DELETE: (id: number) => `/persons/${id}`,
        GET_ONE: (id: number) => `/persons/${id}`,
        UPDATE: (id: number) => `/persons/${id}`,
        SELECT_BOX: '/persons/select-box',
        USAGE: (id: number) => `/persons/${id}/usage`,
    },
    POSITION: {
        GET_ALL: '/positions',
        ADD_NEW: '/positions',
        DELETE: (id: number) => `/positions/${id}`,
        CHECK_POSITION_IS_USED: (id: number) => `/positions/${id}/is-connected-to-any-company`,
        SELECT_BOX: '/positions/select-box',
    },
    FILE_STORE: {
        DOWNLOAD: (id: number) => API_URL + `/file/${id}/download`,
        SHOW: (id: number) => API_URL + `/file/${id}/show`,
        DELETE: (id: number) => API_URL + `/file/${id}`,
        PRIVATE: `/file/private`,
        PUBLIC: `/file/public`,
    },
    COMPANY: {
        ROOT: '/companies',
        DROPDOWN: '/companies/paginated-list',
        SHOW: (id: number) => `/companies/${id}/information`,
        EDIT: (id: number) => `/companies/${id}`,
        UPLOAD_EXCEL: (id: number) => `/companies/${id}/financial-information/upload-excel`,
        SHEETS: (id: number) => `/companies/${id}/financial-information/sheets`,
        SHEET: (id: number, sheetId: number) => `/companies/${id}/financial-information/sheets/${sheetId}`,
        RATIOS: (id: number) => `/companies/${id}/financial-information/ratios`,
        MARKET_RATIOS: (id: number) => `/companies/${id}/financial-information/market-ratios`,
        EXCELS: (id: number) => `/companies/${id}/financial-information/excels`,
        ANNUAL_VALID_YEARS: (id: number) => `/companies/${id}/financial-information/annual/valid-years`,
        QUARTER_VALID_YEARS: (id: number) => `/companies/${id}/financial-information/quarter/valid-years`,
        VALID_QUARTERS: (id: number) => `/companies/${id}/financial-information/years/valid-quarters`,
        SHEET_TYPES: '/companies/financial-information/sheets/types',
        STORE_SHEET_TYPES: '/companies/financial-information/sheet-types',
        FINANCIAL_INFORMATION_SEARCHED_ITEM: '/companies/financial-information/items/search',
        FINANCIAL_INFORMATION_SEARCHED_CATEGORIES: '/companies/financial-information/categories/search',
        FINANCIAL_INFORMATION_SEARCHED_SUB_CATEGORIES: '/companies/financial-information/sub-categories/search',
        FINANCIAL_INFORMATION_CREATE_ITEM: '/companies/financial-information/items',
        CREATE_NEW_COMPANY: '/companies',
        GET_TEMPLATES: '/companies/financial-information/templates',
        SAVE_TEMPLATES: (companyId: number, selectedTemplateId: number) => `/companies/financial-information/${companyId}/template/${selectedTemplateId}`,
        SAVE_CURRENCY_AND_END_YEAR: (companyId: number) => `/companies/financial-information/${companyId}/currency-date`,
        GET_FINANCIAL_TYPES_OF_THIS_COMPANY: (companyId: number) => `/companies/financial-information/${companyId}/financial-types`,
        GET_SELECTED_TYPE_DATES: (companyId: number, selectedType: number) =>
            `/companies/financial-information/${companyId}/types/financial-dates/${selectedType}`,
        GET_ALL_DATES: (companyId: number) => `/companies/financial-information/${companyId}/financial-dates`,
        ADD_COMPANY_DATES: (companyId: number) => `/companies/financial-information/${companyId}/financial-dates`,
        GET_ROWS: (companyId: number, selectedType: number) => `/companies/financial-information/${companyId}/type/values/${selectedType}`,
        GET_ROWS_COMPACT: (companyId: number) => `/companies/financial-information/${companyId}/type/values`,
        GET_ROWS_BY_TYPE: (companyId: number, selectedType: number | undefined) => `/companies/financial-information/${companyId}/types/values/${selectedType}`,
        GET_ROWS_BY_TYPE_COMPACT: (companyId: number) => `/companies/financial-information/${companyId}/types/values`,
        SAVE_ROWS: (companyId: number, selectedType: number) => `/companies/financial-information/${companyId}/types/store-values/${selectedType}`,
        SAVE_ROWS_COMPACT: (companyId: number) => `/companies/financial-information/${companyId}/types/store-values`,
        DELETE_DATE: (companyId: number) => `/companies/financial-information/${companyId}/type`,
        DELETE_ROW: (companyId: number, itemId: number) => `/companies/financial-information/${companyId}/item/${itemId}`,
        FORMULA_USAGE: (companyId: number, itemId: number) => `/companies/financial-information/${companyId}/items/${itemId}/formula-usage`,
        ITEMS_USAGE_IN_FORMULA: (companyType: string, companyId: number, itemId: number) =>
            `/companies/financial-information/${companyType}/${companyId}/items/${itemId}/formula-usage`,
        FINANCIAL_INFORMATION_CATEGORIES: '/companies/financial-information/categories',
        FINANCIAL_INFORMATION_SUB_CATEGORIES: '/companies/financial-information/sub-categories',
        FINANCIAL_INFORMATION_SINGLE_CATEGORY: (categoryId: number) => `/companies/financial-information/categories/${categoryId}`,
        FINANCIAL_INFORMATION_SINGLE_SUB_CATEGORY: (subCategoryId: number) => `/companies/financial-information/sub-categories/${subCategoryId}`,
        FINANCIAL_INFORMATION_TYPES: '/companies/financial-information/types/select-box',
        IS_ITEM_USED_IN_SOME_COMPANY: (id: number) => `/companies/financial-information/items/${id}/is-used`,
        DELETE_ITEM: (id: number) => `/companies/financial-information/items/${id}`,
        FINANCIAL_REPORTS: (companyId: number) => `/companies/${companyId}/financial-reports`,
        FINANCIAL_REPORTS_EDIT: (companyId: number, id: number) => `/companies/${companyId}/financial-reports/${id}`,
        FINANCIAL_REPORTS_GET_LANGUAGES: '/companies/financial-reports/list/languages',
        FINANCIAL_INFORMATION_CHANGE_STATUS: '/companies/financial-information/change/status',
        PEOPLE_LIST: (companyId: number) => `/companies/${companyId}/people/list`,
        PEOPLE_SHOW_PERSON: (companyId: number, id: number) => `/companies/${companyId}/people/${id}`,
        PEOPLE_ASSIGN_PERSON_TO_COMPANY: (companyId: number) => `/companies/${companyId}/people/assign-person-to-company`,
        PEOPLE_UPDATE_ASSIGNED_PERSON_FROM_COMPANY: (companyId: number, id: number) =>
            `/companies/${companyId}/people/${id}/update-assigned-person-from-company`,
        PEOPLE_REMOVE_ASSIGNED_PERSON_FROM_COMPANY: (companyId: number, id: number) =>
            `/companies/${companyId}/people/${id}/remove-assigned-person-from-company`,
        PEOPLE_ASSIGN_POSITION_TYPES: '/companies/people/assign-position-types',
        CREATE_DIVIDENDS: (companyId: number) => `/companies/${companyId}/dividends`,
        UPDATE_DIVIDENDS: (companyId: number, dividendID: number) => `/companies/${companyId}/dividends/${dividendID}`,
        ALL_DIVIDENDS: (companyId: number) => `/companies/${companyId}/dividends`,
        CHANGE_STATUS: (companyId: number) => `/companies/${companyId}/change-status`,
        FINANCIAL_INFORMATION_RATIOS: (companyId: number) => `/companies/financial-information/${companyId}/ratios`,
        FINANCIAL_INFORMATION_RATIOS_DATES: (companyId: number) => `/companies/financial-information/${companyId}/ratios/dates`,
        PARENT_COMPANY_FINANCIAL: (companyId: number) => `/companies/${companyId}/parent-financial`,
        DELETE_PARENT_COMPANY_FINANCIAL: (companyId: number, id: number) => `/companies/${companyId}/parent-financial/${id}`,
        CREATE_PARENT_COMPANY_FINANCIAL: (companyId: number) => `/companies/${companyId}/parent-financial`,
        UPDATE_PARENT_COMPANY_FINANCIAL: (companyId: number, id: number) => `/companies/${companyId}/parent-financial/${id}`,
        GET_ONE_PARENT_COMPANY_FINANCIAL: (companyId: number, id: number) => `/companies/${companyId}/parent-financial/${id}`,
        COMMENTS: (companyId: number) => `/companies/${companyId}/comments`,
        CERATE_COMMENT: (companyId: number) => `/companies/${companyId}/comments/store`,
        UPDATE_COMMENT: (companyId: number, id: number) => `/companies/${companyId}/comments/${id}`,
        DELETE_COMMENT: (companyId: number, id: number) => `/companies/${companyId}/comments/${id}`,
        SHOW_COMMENT: (companyId: number, id: number) => `/companies/${companyId}/comments/${id}`,
        FEEDS: '/companies/feeds/status',
        FINANCIAL_INFO_DRAFT_STORE: () => `/companies/financial-information/draft/store`,
        FINANCIAL_INFO_DRAFT_BASE: (id: number) => `/companies/financial-information/draft/${id}`,
    },
    DIVIDENDS: {
        GET_TEMPLATES: '/dividends/templates',
        GET_TEMPLATES_FIELDS: (templateId: number) => `/dividends/templates/${templateId}/dates`,
        DIVIDENDS_EDIT: (companyId: number, dividendID: number) => `companies/${companyId}/dividends/${dividendID}`,
        DELETE: (companyId: number, dividendID: number) => `companies/${companyId}/dividends/${dividendID}`,
    },
    NOTIFICATION: {
        ROOT: '/notifications',
        UNREAD_NOTIFICATIONS_COUNT: '/notifications/unread-notifications-count',
        MARK_AS_READ: '/notifications/mark-as-read',
        EXCEL_NOTIFICATIONS: '/notifications/excel-notifications',
        EXCEL_NOTIFICATIONS_MARK_AS_READ: '/notifications/excel-notifications/mark-as-read',
    },
    CURRENCY: {
        ROOT: '/currencies/select-box',
    },
    COUNTRIES: {
        GET_ALL: '/countries/select-box',
        GET_LIST: '/countries/list',
    },
    STOCK_EXCHANGES_SERVICE: {
        GET_ALL_DROPDOWN_OPTIONS: '/stock-exchanges/select-box',
        GET_ALL_STOCK_EXCHANGES: '/stock-exchanges',
        ADD_NEW: '/stock-exchanges',
        GET_ONE: (id: number) => `/stock-exchanges/${id}`,
        UPDATE: (id: number) => `/stock-exchanges/${id}`,
        DELETE: (id: number) => `/stock-exchanges/${id}`,
    },
    LEGAL_STRUCTURES_SERVICE: {
        GET_ALL_DROPDOWN_OPTIONS: '/legal-structures/options',
        GET_ALL_STOCK_EXCHANGES: '/legal-structures',
        ADD_NEW: '/legal-structures',
        GET_ONE: (id: number) => `/legal-structures/${id}`,
        UPDATE: (id: number) => `/legal-structures/${id}`,
        DELETE: (id: number) => `/legal-structures/${id}`,
    },
    SECTOR: {
        GET_ALL_SECTOR_DROPDOWN_OPTIONS: '/sectors/list/options',
        GET_ALL_SECTORS: '/sectors',
        GET_ALL_COMPANY_TYPES: `/sectors/list/company-types`,
        ADD_NEW: '/sectors',
        GET_ONE: (id: number) => `/sectors/${id}`,
        UPDATE: (id: number) => `/sectors/${id}`,
        DELETE: (id: number) => `/sectors/${id}`,
    },
    SCREENER: {
        GET_ALL_SCREENERS_DROPDOWN_OPTIONS: '/screeners/list/options',
        GET_ALL_SCREENERS: '/screener',
        GET_ALL_SCREENERS_COMPANIES: '/screener/companies',
        GET_ALL_COMPANY_TYPES: `/screeners/list/company-types`,
        GET_ONE: (id: number) => `/screener/${id}/companies`,
        MODIFY: `/screener/modify`,
        DELETE: (screenerId: number, companyId: number) => `/screener/${screenerId}/companies/${companyId}`,
        DELETE_SCREENER: (screenerId: number) => `/screener/${screenerId}`,
    },
    TAG: {
        GET_ALL_TAGS: '/tags',
        CREATE: '/tags',
        DROPDOWN: '/tags/select-options',
        GET_ONE: (id: number) => `/tags/${id}`,
        DELETE: (tagId: ITagListResponse['id']) => `/tags/${tagId}`,
    },
    NEWS_SOURCE: {
        GET_ALL: '/news/source',
        DELETE: (id: number) => `/news/source/${id}`,
        ADD_NEW: '/news/source',
        UPDATE: (id: number) => `/news/source/${id}`,
        GET_ONE: (id: number) => `/news/source/${id}`,
        LIST: `/news/source/list`,
    },
    SHARE_HOLDER: {
        GET_ALL_SHARE_HOLDERS: (companyId: number) => `/companies/${companyId}/shareholders`,
        ADD_NEW: (companyId: number) => `/companies/${companyId}/shareholders`,
        GET_ONE: (companyId: number, shareHolderId: number) => `/companies/${companyId}/shareholders/${shareHolderId}`,
        UPDATE: (companyId: number, shareHolderId: string) => `/companies/${companyId}/shareholders/${shareHolderId}`,
        DELETE: (companyId: number, shareHolderId: number) => `/companies/${companyId}/shareholders/${shareHolderId}`,
    },
    HOLDINGS: {
        GET_ALL_HOLDINGS: (companyId: number) => `/companies/${companyId}/holdings`,
        ADD_NEW: (companyId: number) => `/companies/${companyId}/holdings`,
        DELETE: (companyId: number, holdingId: number) => `/companies/${companyId}/holdings/${holdingId}`,
        GET_ONE: (companyId: number, holdingId: number) => `/companies/${companyId}/holdings/${holdingId}`,
        UPDATE: (companyId: number, holdingId: number) => `/companies/${companyId}/holdings/${holdingId}`,
    },
    NEWS: {
        GET_ALL_BY_ENTITY: (entityType: string, entityId: number) => `/news/${entityType}/${entityId}`,
        ADD_NEW: `/news`,
        GET_ONE: (id: number) => `/news/${id}`,
        UPDATE: (id: number) => `/news/${id}`,
        DELETE: (id: number) => `/news/${id}`,
    },
    CURRENCY_EXCHANGE: {
        GET_ALL: '/currencies/exchange-rates',
        GET_ONE: (exchangeRate: number) => `/currencies/exchange-rates/${exchangeRate}`,
        MODIFY: (exchangeRate: number) => `/currencies/exchange-rates/${exchangeRate}`,
        CREATE: `/currencies/exchange-rates`,
    },
    ENTITY: {
        TYPES: '/entity/types',
        SEARCH: '/entity/search',
    },
    WIDGET: {
        GET_ALL: '/settings/widgets',
        UPDATE: '/settings/widgets',
    },
    MESSAGES: {
        GET_ALL: '/messages',
    },
};
